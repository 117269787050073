import {map, take} from 'rxjs/operators';
import {Action, AngularFirestore, DocumentChangeAction, DocumentSnapshot, QueryFn} from '@angular/fire/firestore';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/internal/observable/from';
import * as _ from 'lodash';

export class FirestoreBaseService<T extends FirestoreBaseModel> {

    protected path: string;
    protected afs: AngularFirestore;

    constructor(
        path: string,
        afs: AngularFirestore,
    ) {
        this.path = path;
        this.afs = afs;
    }

    getData(queryFn?: QueryFn): Observable<T[]> {
        return this.getDataListener(queryFn).pipe(take(1));
    }

    getDataListener(queryFn?: QueryFn): Observable<T[]> {
        return this.afs.collection(this.path, queryFn).snapshotChanges().pipe(
            map((actions: DocumentChangeAction<T>[]) => this.mapDocumentChangeActions(actions)));
    }

    getDataById(id: string): Observable<T> {
        return this.getDataByIdListener(id).pipe(take(1));
    }

    getDataByIdListener(id: string): Observable<T> {
        return this.afs.doc(this.path + '/' + id).snapshotChanges().pipe(map((action: Action<DocumentSnapshot<T>>) => {
            const data = action.payload.data() as T;
            const id = action.payload.id;
            if (!data) return null;
            return this.mapData(id, data);
        }));
    }

    mapDocumentChangeActions(actions: DocumentChangeAction<T>[]): T[] {
        return actions.map(a => {
            const data = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return this.mapData(id, data);
        });
    }

    mapData(id: string, data: T): T {
        // @ts-ignore
        const newObject: T = data ? _.cloneDeep(data) : {};
        return Object.assign(newObject, { id: id });
    }

    // add-project
    post(obj: T): Observable<T> {
        return Observable.create(observer => {
            this.afs.collection(this.path).add(obj).then(data => {
                obj.id = data.id;
                observer.next(obj);
                observer.complete();
            });
        });
    }

    // add-project with given id
    insert(obj: T, id: string): Observable<T> {
        return Observable.create(observer => {
            this.afs.collection(this.path).doc(id).set(obj);
            observer.next(this.mapData(id, obj));
            observer.complete();
        });
    }

    update(obj: T, id: string): Observable<void> {
        console.log(this.path + '\n' + id + '\n' + JSON.stringify(obj), {merge: true});
        return Observable.create(observer => {
            this.afs.collection(this.path).doc(id).update(obj);
            observer.next();
            observer.complete();
        });
    }

    delete(id: string): Observable<void> {
        return from(this.afs.collection(this.path).doc(id).delete());
    }

    toDataObj(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }



    getSubDataListener(subcollection: string, docid: string, queryFn?: QueryFn): Observable<T[]> {
        console.log('Path: ' + this.path + ', doc: ' + docid);
        return this.afs.collection(this.path).doc(docid).collection(subcollection, queryFn).snapshotChanges().pipe(
            map((actions: DocumentChangeAction<T>[]) => this.mapDocumentChangeActions(actions)));
    }
}


export class FirestoreBaseModel {
    id: string;
}
