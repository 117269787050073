import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DialogDataProject} from '../../utils/dialogData-project';
import {FbUserService, UserService} from '../../services/user-service';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss']
})
export class UsernameComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UsernameComponent>,
    protected currUser: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProject,
    protected userService: FbUserService,
  ) {
  }
  formControl = new FormControl('', [Validators.required]);
  name = '';

  ngOnInit() {
  }



  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(name: string): void {
    this.currUser.getUser().subscribe(usr => {
      if (usr.authUser.uid != null) {
        usr.user.name = name;
        this.userService.update(usr.user, usr.authUser.uid).subscribe(
          () => console.log('Name added successfully'),
          (e) => console.log(e),
        );
      }
    });
  }
}
