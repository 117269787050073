interface NavAttributes {
    [propName: string]: any;
}

interface NavWrapper {
    attributes: NavAttributes;
    element: string;
}

interface NavBadge {
    text: string;
    variant: string;
}

interface NavLabel {
    class?: string;
    variant: string;
}

export interface NavData {
    name?: string;
    url?: string;
    icon?: string;
    badge?: NavBadge;
    title?: boolean;
    children?: NavData[];
    variant?: string;
    attributes?: NavAttributes;
    divider?: boolean;
    class?: string;
    label?: NavLabel;
    wrapper?: NavWrapper;
    admin?: boolean;
}

export const navItems: NavData[] = [

    {
        name: 'Overview',
        url: '/dashboard',
        icon: 'icon-grid',
        admin: true
    },
    {
        title: true,
        name: 'Pages'
    },
    {
        name: 'Projects',
        url: '/projects',
        icon: 'icon-docs'
    },
    {
        name: 'Users',
        url: '/users',
        icon: 'icon-people',
        admin: true,
    },
    {
        divider: true
    },
    {
        title: true,
        name: 'Extras',
    },
    {
        name: 'Feedback',
        url: '/feedback',
        icon: 'icon-heart',
    },
    {
        name: 'Version 1.0',
        url: '/version',
        icon: 'icon-wrench',
    },
];

