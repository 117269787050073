import {Injectable} from '@angular/core';
import {FirestoreBaseService} from './database-service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Timestamp} from '@firebase/firestore-types';

@Injectable()
export class FbProjectService extends FirestoreBaseService<ProjectModel> {

  constructor(afs: AngularFirestore) {
    super('projects', afs);
  }
}


export class ProjectModel {
  id: string;
  name: string;
  project_id: string;
  createdAt: Timestamp;
  rooms?: string[];
  notes: Notes[];
  subscribers?: string[];
  createdName?: string;
  createdBy: string;
}

export class Notes {
  createdAt: Timestamp;
  createdBy: string;
  note: string;
}


