import {Component, Inject, Input, OnInit} from '@angular/core';
import {ProjectModel} from '../../services/project-service';
import {MatDialogRef} from '@angular/material';
import {FbUserService} from '../../services/user-service';


@Component({
    selector: 'app-show-notes',
    templateUrl: './show-notes.component.html',
    styleUrls: ['./show-notes.component.scss']
})
export class ShowNotesComponent implements OnInit {

    loaded = false;
    @Input()
    project: ProjectModel;

    constructor(
        public dialogRef: MatDialogRef<ShowNotesComponent>,
        private userService: FbUserService,
    ) {}

    ngOnInit() {
        this.userService.getData().subscribe(usrs => {
            this.project.notes.forEach(note => {
                usrs.forEach(usr => {
                    if (note.createdBy === usr.id) {
                        note['createdByName'] = usr.name;
                    }
                });
            });
            this.loaded = true;
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
