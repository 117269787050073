import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DialogDataProject} from '../../utils/dialogData-project';
import {FbProjectService, ProjectModel} from '../../services/project-service';
import {UserService} from '../../services/user-service';
import {firestore} from 'firebase';
import Timestamp = firestore.Timestamp;


@Component({
  selector: 'app-add',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddProjectComponent>,
    protected currUser: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProject,
    protected projectService: FbProjectService,
  ) {}

  formControl = new FormControl('', [Validators.required]);
  description = '';
  number = '';

  ngOnInit() {
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(id: string, description: string): void {
    const date = new Date();
    this.currUser.getUser().subscribe(usr => {
      if (usr.authUser.uid != null) {
        const newProjectModel = new ProjectModel();
        newProjectModel.name = description;
        newProjectModel.project_id = id.toUpperCase();
        newProjectModel.createdBy = usr.authUser.uid;
        newProjectModel.createdAt = Timestamp.now();
        newProjectModel.subscribers = [usr.authUser.uid];
        newProjectModel.rooms = ['Alla'];
        newProjectModel.notes = [];
        console.log(JSON.stringify(newProjectModel));
        this.projectService.post(JSON.parse(JSON.stringify(newProjectModel))).subscribe(
          () => console.log('Added')
        );
      }
    });
  }
}
