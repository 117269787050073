import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import {UserService} from '../services/user-service';
import {MatDialog} from '@angular/material';
import {UsernameComponent} from '../dialogs/username/username.component';


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        public userService: UserService,
        public dialog: MatDialog,
    ) {
        this.userService.getUser().subscribe(usr => {
            if (usr && usr.user && usr.user.name && usr.user.name.length < 1) {
                console.log('No username');
                this.dialog.open(UsernameComponent, {disableClose: true});
            }
        });
    }

    canActivate(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userService.getCurrentUser()
                .then(user => {
                    console.log('Access');
                    return resolve(true);
                }, err => {
                    console.log('No access');
                    this.router.navigate(['/login']);
                    return reject(false);
                });
        });
    }


}
