import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {navItems} from '../../_nav';
import {CurrentUser, UserService} from '../../services/user-service';
import {AuthenticationService, Role} from '../../services/auth-service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy, OnInit {
    public navItems = navItems;
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement;
    public user: CurrentUser;
    appName = environment.appName;

    constructor(private userService: UserService, public authService: AuthenticationService,
                private router: Router,
                @Inject(DOCUMENT) _document?: any) {

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
        });
        this.element = _document.body;
        this.changes.observe(<Element>this.element, {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    ngOnInit(): void {
        this.userService.getUser().subscribe(usr => {
            this.user = usr;
                if (this.user && this.user.user && this.user.user.role !== Role.ADMIN) {
                    this.navItems = this.navItems.filter(item => item.admin !== true);
                }
        });
    }

    ngOnDestroy(): void {
        this.changes.disconnect();
    }

    tryLogout() {
        this.authService.doLogout()
            .then(res => {
                this.router.navigate(['/login']);
            }, err => {
                console.log(err);
            });
    }
}
