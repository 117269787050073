import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DialogDataProject} from '../../utils/dialogData-project';
import {UserService} from '../../services/user-service';
import {firestore} from 'firebase';
import {BarcodeModel, FbBarcodeService} from '../../services/barcode-service';
import Timestamp = firestore.Timestamp;


@Component({
  selector: 'app-add-barcode',
  templateUrl: './add-barcode.component.html',
  styleUrls: ['./add-barcode.component.scss']
})
export class AddBarcodeComponent implements OnInit {

  @Input()
  projectId: string;

  constructor(
    public dialogRef: MatDialogRef<AddBarcodeComponent>,
    protected currUser: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProject,
    protected barcodeService: FbBarcodeService,
  ) {}

  formControl = new FormControl('', [Validators.required]);
  description = '';
  number = '';

  ngOnInit() {
    console.log('id: ' + this.projectId);
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmAdd(id: string, description: string): void {

    this.currUser.getUser().subscribe(usr => {
      if (usr.authUser.uid != null) {
        const newBarcodeModel = new BarcodeModel();
        newBarcodeModel.barcode = id;
        newBarcodeModel.description = description;
        newBarcodeModel.project = this.projectId;
        newBarcodeModel.createdAt = Timestamp.now();
        newBarcodeModel.createdBy = usr.authUser.uid;
        newBarcodeModel.room = '';
        this.barcodeService.post(JSON.parse(JSON.stringify(newBarcodeModel))).subscribe(
          () => console.log('sparat'), //this.toastr.success('SN-kod: ' + newBarcodeModel.barcode + '\nBeskrivning: ' + newBarcodeModel.description, 'Tillagt!'),
          err => console.log('error'), //this.toastr.error(err, 'Något gick fel :/'),
          () => this.dialogRef.close,
        );
      }
    });
  }
}
