import {Injectable} from '@angular/core';
import {FbUserService} from '../../services/user-service';
import {FbBarcodeService} from '../../services/barcode-service';
import {FbProjectService} from '../../services/project-service';
import {Observable, combineLatest} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import 'rxjs-compat/add/observable/of';


export class DashboardModel {
    registerdUser: number;
    barcodesCreated: number;
    activeProjectsCount: number;
    barcodesByDate: {};
    projectsByDate: {};
    createdUsersByDate: {};
}

@Injectable()
export class DashboardServiceComponent {
    constructor(private userService: FbUserService,
                private barcodeService: FbBarcodeService,
                private projectService: FbProjectService) {
    }

    getData(): Observable<DashboardModel> {
        return this.userService.getDataListener().pipe(
            mergeMap(users => combineLatest(
                Observable.of(users),
                this.barcodeService.getDataListener(ref => ref.orderBy('createdAt')),
                this.projectService.getDataListener(ref => ref.orderBy('createdAt')),
            ))).map(res => {
            const users = res[0];
            const barcodes = res[1];
            const projects = res[2];

            const model = new DashboardModel();
            model.registerdUser = users.length;
            model.barcodesCreated = barcodes.length;
            model.activeProjectsCount = projects.length;
            model.barcodesByDate = this.extractToDateGroup(barcodes);
            model.projectsByDate = this.extractToDateGroup(projects);
            model.createdUsersByDate = this.extractToDateGroup(users);
            return model;
        });
    }

    extractToDateGroup(data) {
        const groups = {};

        data.forEach(function (val) {
            const date = val.createdAt.toDate().toISOString().split('T')[0];
            if (date in groups) {
                groups[date].push(val);
            } else {
                groups[date] = new Array(val);
            }
        });
        return groups;
    }

    compare(a: any, b: any) {
        if (a.createdAt > b.createdAt) {
            return 1;
        }
        if (b.createdAt < a.createdAt) {
            return -1;
        }

        return 0;
    }
}

