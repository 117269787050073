import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type'
    })
};

@Injectable()
export class FunctionService {

    constructor(public http: HttpClient) {
    }


    createUser(usr) {
        return this.http.post(environment.BASE_API_URL + '/createNewUser', JSON.stringify(usr), httpOptions);
    }

    disableUser(uid: string) {
        return this.http.post(environment.BASE_API_URL + '/disableUser', {uid: uid}, httpOptions);
    }

    activateUser(uid: string) {
        return this.http.post(environment.BASE_API_URL + '/activateUser', {uid: uid}, httpOptions);
    }

    sendFeedbackEmail(email: string, message: string, type: string) {
        const body = {email: email, message: message, type: type};
        return this.http.post(environment.BASE_API_URL + '/sendFeedbackhttp', body);
    }
}
