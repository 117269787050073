import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DialogDataProject} from '../../utils/dialogData-project';

//import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  project_id: string;
  name: string;
  constructor(
    public dialogRef: MatDialogRef<EditProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataProject,
    //private toastr: ToastrService,
  ) {}

  formControl = new FormControl('', [Validators.required]);

  ngOnInit() {
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getProjectID(event) {
    this.project_id = event;
  }

  getName(event) {
    this.name = event;
  }
  stopEdit(): void {
    const id = this.data.project.id;

    if (this.name) {
      this.data.project.name = this.name;
    }

    delete this.data.project.createdName;
    delete this.data.project.id;

    this.data.projectService.update(this.data.project, id).subscribe(
      () =>  console.log('asd'),//this.toastr.success('', 'Uppdaterat!'),
      e => console.log(e), //this.toastr.error(e.error.message, 'Något gick fel :/'),
    );
  }
}
