import {Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {FirestoreBaseService} from './database-service';
import {AuthenticationService, User} from './auth-service';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase';


@Injectable()
export class FbUserService extends FirestoreBaseService<User> {

    constructor(afs: AngularFirestore) {
        super('users', afs);
    }
}

@Injectable()
export class UserService extends FbUserService {

    constructor(public authService: AuthenticationService, afs: AngularFirestore) {
        super(afs);
    }

    getUser(): Observable<CurrentUser> {
        return this.authService.getFirebaseUser().pipe(
            switchMap((fbUser: firebase.User) => {
                if (fbUser) {
                    return combineLatest(of(fbUser), this.getDataByIdListener(fbUser.uid));
                }
                return combineLatest(of(null, null));
            })
        ).map(([fbUser, user]: [firebase.User, User]) => {
            const usr = new CurrentUser();
            usr.authUser = fbUser;
            usr.user = user;

            return usr;
        });
    }

    getCurrentUser() {
        return new Promise<any>((resolve, reject) => {
            let user = firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    resolve(user);
                } else {
                    reject();
                }
            });
        });
    }

    getUserById(usrId: string): Observable<User[]> {
        return forkJoin(this.getDataById(usrId).map(user => {
            return user;
        }));
    }

}

export class CurrentUser {
    authUser: firebase.User;
    user: User;
}


export class NewUser {
    email: string;
    role: string;

    constructor(
    ) {}
}
