// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The barcodeList of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  appName: 'SN2Room dev',
  BASE_API_URL: 'https://us-central1-sn2room-dev.cloudfunctions.net',
  firebaseConfig: {
    apiKey: 'AIzaSyANggwtJom-17rcw0LdH22FOxybIS_qicA',
    authDomain: 'sn2room-dev.firebaseapp.com',
    databaseURL: 'https://sn2room-dev.firebaseio.com',
    projectId: 'sn2room-dev',
    storageBucket: 'sn2room-dev.appspot.com',
    messagingSenderId: '762172540375',
    appId: '1:762172540375:web:1106ee59aa1b9909'
  }
};

