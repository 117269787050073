import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/auth-service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertConfig} from 'ngx-bootstrap';
import {UserService} from '../../services/user-service';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {CreateUserDialogComponent} from '../../dialogs/create-user/create-user-dialog.component';
import {ResetPasswordComponent} from '../../dialogs/reset-password/reset-password.component';

export function getAlertConfig(): AlertConfig {
    return Object.assign(new AlertConfig(), { type: 'success' });
}

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  providers: [{provide: AlertConfig, useFactory: getAlertConfig}]
})
export class LoginComponent implements OnInit {

  errorMessage = '';
  loginForm: FormGroup;

  constructor(
      public authService: AuthenticationService,
      private router: Router,
      private userService: UserService,
      private fb: FormBuilder,
      public dialog: MatDialog,) {
    this.createForm();
  }

  ngOnInit() {

    this.userService.getCurrentUser()
        .then(user => {
          this.router.navigate(['/']);
        }, err => {
          console.log('No access');
        });
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  tryLogin(value) {
    this.authService.doLogin(value)
        .then(res => {
          this.router.navigate(['/']);
        }, err => {
          console.log(err);
          this.errorMessage = err.message;
        });
  }

  resetPassword() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      this.dialog.open(ResetPasswordComponent, dialogConfig);
  }


}
