import {mergeMap} from 'rxjs/operators';
import 'rxjs/add/operator/map';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {Observable} from 'rxjs/Observable';
import {from} from 'rxjs/internal/observable/from';
import UserCredential = firebase.auth.UserCredential;


@Injectable()
export class AuthenticationService {

    constructor(
        private afAuth: AngularFireAuth,
    ) {
    }

    login(username: string, password: string): Observable<boolean> {
        return from(this.afAuth.auth.signInWithEmailAndPassword(username, password)).map(
            (userCredentials: firebase.auth.UserCredential) => {
                return true;
            }
        );
    }

    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password)
                .then(res => {
                    resolve(res);
                }, err => reject(err));
        });
    }

    doLogout() {
        return new Promise((resolve, reject) => {
            if (this.afAuth.auth.currentUser) {
                this.afAuth.auth.signOut();
                resolve();
            } else {
                reject();
            }
        });
    }


    // Testar för error handling
    newLogin(username: string, password: string): Promise<UserCredential> {
        return this.afAuth.auth.signInWithEmailAndPassword(username, password);
    }

    logout(): Observable<boolean> {
        return from(this.afAuth.auth.signOut()).map(res => true);
    }

    getTokenId(): Observable<string> {
        return this.afAuth.user.pipe(mergeMap(user => user.getIdToken()));
    }

    getFirebaseUser(): Observable<firebase.UserInfo> {
        return this.afAuth.user;
    }

    resetPassword(email: string): Observable<boolean> {
        console.log(email);
        return from(this.afAuth.auth.sendPasswordResetEmail(email)
            .then(() => {
                return true;
            })
            .catch((error) => {
                return false;
            }));
    }

}

export class User {
    id: string;
    email: string;
    name?: string;
    createdAt: Date;
    role: Role;
    disabled: boolean;
}


export enum Role {
    ADMIN = 'admin',
    MEMBER = 'member',
}
