import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DialogDataBarcode} from '../../utils/dialogData-barcode';


@Component({
  selector: 'app-edit',
  templateUrl: './edit-barcode.component.html',
  styleUrls: ['./edit-barcode.component.scss']
})
export class EditComponent implements OnInit {
  barcode: string;
  description: string;
  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataBarcode,

  ) {}

  formControl = new FormControl('', [Validators.required]);

  ngOnInit() {
  }

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' : '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getBarcode(event) {
    this.barcode = event;
  }

  getDescription(event) {
    this.description = event;
  }
  stopEdit(): void {
    const id = this.data.barcode.id;
    if (this.barcode) {
      this.data.barcode.barcode = this.barcode;
    }
    if (this.description) {
      this.data.barcode.description = this.description;
    }

    delete this.data.barcode.createdName;
    delete this.data.barcode.createdEmail;
    delete this.data.barcode.id;

    this.data.barcodeService.update(this.data.barcode, id).subscribe(
      () =>  console.log('Uppdaterat'),
      e => console.log(e.error.message + 'Något gick fel :/'),
    );
  }
}
