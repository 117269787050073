import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import 'rxjs/add/operator/toPromise';
import {CurrentUser, UserService} from '../services/user-service';
import {Role} from '../services/auth-service';


@Injectable()
export class AuthGuardAdmin implements CanActivate {

    constructor(
        private router: Router,
        private currentUser: UserService,
    ) {
    }

    canActivate(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.currentUser.getUser().subscribe((usr: CurrentUser) => {
                if (usr && usr.user && usr.user.role != null && usr.user.role === Role.ADMIN) {
                    console.log('Admin access');
                    return resolve(true);
                } else {
                    console.log('No admin access');
                    this.router.navigate(['/projects']);
                    return reject(false);
                }
            });
        });
    }

}
