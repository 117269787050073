import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {AuthGuard} from './core/auth.guard';
import {AuthGuardAdmin} from './core/auth.guard.admin';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuardAdmin],
      },
      {
        path: 'projects',
        loadChildren: './views/projects/base.module#BaseModule',
      },
      {
        path: 'users',
        canActivate: [AuthGuardAdmin],
        loadChildren: './views/users/users-base.module#UsersBaseModule',
      },
      {
        path: 'feedback',
        loadChildren: './views/feedback/feedback.module#FeedbackModule',
      },
      {
        path: 'version',
        loadChildren: './views/version/version.module#VersionModule',
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
