import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

import {PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {AppComponent} from './app.component';
// Import containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {AppAsideModule, AppBreadcrumbModule, AppFooterModule, AppHeaderModule, AppSidebarModule,} from '@coreui/angular';
// Import routing module
import {AppRoutingModule} from './app.routing';

import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AngularFireStorageModule} from 'angularfire2/storage';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertModule} from 'ngx-bootstrap';
import {MatMenuModule} from '@angular/material/menu';
// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {ChartsModule} from 'ng2-charts/ng2-charts';
import {environment} from '../environments/environment';
import {FbUserService, UserService} from './services/user-service';
import {AuthenticationService} from './services/auth-service';
import {AuthGuard} from './core/auth.guard';
import {EditComponent} from './dialogs/edit-barcode/edit-barcode.component';
import {BasicDialogComponent} from './dialogs/basic-dialog/basic-dialog.component';
import {EditProjectComponent} from './dialogs/edit-project/edit-project.component';
import {AddBarcodeComponent} from './dialogs/add-barcode/add-barcode.component';
import {AddProjectComponent} from './dialogs/add-project/add-project.component';
import {UsernameComponent} from './dialogs/username/username.component';
import {FbProjectService} from './services/project-service';
import {FbBarcodeService} from './services/barcode-service';
import {FunctionService} from './services/function-service';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CreateUserDialogComponent} from './dialogs/create-user/create-user-dialog.component';
import {AuthGuardAdmin} from './core/auth.guard.admin';
import {DashboardServiceComponent} from './views/dashboard/dashboard-service.component';
import {ShowNotesComponent} from './dialogs/show-notes/show-notes.component';
import {ResetPasswordComponent} from './dialogs/reset-password/reset-password.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

const APP_CONTAINERS = [
    DefaultLayoutComponent
];


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        AlertModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRippleModule,
        MatCheckboxModule,
        MatMenuModule,
        MatSnackBarModule,
        FormsModule,
        MatTooltipModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        HttpClientModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        LoginComponent,
        CreateUserDialogComponent,
        BasicDialogComponent,
        EditComponent,
        EditProjectComponent,
        AddProjectComponent,
        AddBarcodeComponent,
        UsernameComponent,
        ShowNotesComponent,
        ResetPasswordComponent
    ],
    providers: [{
        provide: LocationStrategy,
        useClass: HashLocationStrategy
    },
        AuthGuard,
        AuthGuardAdmin,
        AuthenticationService,
        UserService,
        FbUserService,
        FbProjectService,
        FbBarcodeService,
        FunctionService,
        DashboardServiceComponent],
    bootstrap: [AppComponent],
    entryComponents: [
        CreateUserDialogComponent,
        BasicDialogComponent,
        EditComponent,
        EditProjectComponent,
        AddProjectComponent,
        AddBarcodeComponent,
        UsernameComponent,
        ShowNotesComponent,
        ResetPasswordComponent
    ]
})
export class AppModule {
}
