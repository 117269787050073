import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as firebase from 'firebase';

@Component({
    selector: 'app-dialog',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    form: FormGroup;
    error = '';
    loading = false;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<ResetPasswordComponent>,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'),]
        });
    }

    resetPassword() {
        this.error = '';
        this.loading = true;
        const auth = firebase.auth();
        return auth.sendPasswordResetEmail(this.form.get('email').value.toString())
            .then(() => {
                console.log('email sent');
                this.loading = false;
            })
            .catch((error) => {
                console.log(error);
                this.error = error.data.error.message;
                this.loading = false;
            });
    }

    close() {
        this.dialogRef.close();
    }

}
