import {Component, Input, OnInit} from '@angular/core';

export class Action {
  name: string;
  action: (any) => void;
}

@Component({
  selector: 'app-mail-download-choice',
  templateUrl: './basic-dialog.component.html',
  styleUrls: ['./basic-dialog.component.scss']
})
export class BasicDialogComponent implements OnInit {

  @Input()
  title: string;
  @Input()
  message: string;
  @Input()
  actions: Action[];
  @Input()
  loading: boolean;

  constructor(
  ) {
    this.loading = false;
  }

  ngOnInit() {
  }

}

