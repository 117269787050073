import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FunctionService} from '../../services/function-service';
//import { ToastrService } from 'ngx-toastr';
import {NewUser} from '../../services/user-service';


@Component({
    selector: 'app-dialog',
    templateUrl: './create-user-dialog.component.html',
    styleUrls: ['./create-user-dialog.component.scss']
})
export class CreateUserDialogComponent implements OnInit {

    form: FormGroup;
    error = '';
    loading = false;

    constructor(
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<CreateUserDialogComponent>,
        private cloudService: FunctionService,
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.pattern('^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$'),],
            userrole: ['', Validators.required]
        });
    }

    save() {
        this.error = '';
        this.loading = true;
        const usr = new NewUser();
        usr.email = this.form.get('email').value.toString();
        usr.role = this.form.get('userrole').value.toString();
        this.cloudService.createUser({data: {role: usr.role, email: usr.email}}).subscribe(
            () => {
                console.log('created');
                this.loading = false;
                this.dialogRef.close(this.form.value);
            },
            (err) => {
                console.log(err);
                this.error = err.error.data.message;
                this.loading = false;
            }
        );
    }

    close() {
        this.dialogRef.close();
    }

}
