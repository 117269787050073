import {Injectable} from '@angular/core';
import {FirestoreBaseService} from './database-service';
import {AngularFirestore} from '@angular/fire/firestore';
import {Timestamp} from '@firebase/firestore-types';

@Injectable()
export class FbBarcodeService extends FirestoreBaseService<BarcodeModel> {

  constructor(afs: AngularFirestore) {
    super('barcodes', afs);
  }
}


export class BarcodeModel {
  id: string;
  barcode: string;
  description: string;
  createdAt:  Timestamp;
  createdBy: string;
  project: string;
  room?: string;
  note: Map<string, any>;
  createdName?: string;
  createdEmail?: string;
  updatedAt:  Timestamp;

}
